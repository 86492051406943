// URLs
export const LINKEDIN_URL = "https://www.linkedin.com/in/cole-stankov/";
export const GITHUB_URL = "https://github.com/cstankov";

// CBR Manager Constants
export const CBR_NAME = "MyCBR Manager";
export const CBR_DESCRIPTION =
  "Worked directly with members of the Hope Health Action charity in an agile development environment in a team of six to design and create MyCBR. MyCBR helps the CBR worker schedule, maintain and analyze clients for purpose of rehabilitation.";
export const CBR_TECH = "Android Studio, Java, PostgreSQL";
export const CBR_GITHUB_LINK = "https://github.com/cstankov/CBR-Manager";

// Portfolio Constants
export const PORTFOLIO_NAME = "Portfolio Website";
export const PORTFOLIO_DESCRIPTION1 =
  "The amazing website that you are currently viewing which is currently being hosted on Github pages.";
export const PORTFOLIO_TECH = "ReactJS, HTML, CSS";
export const PORTFOLIO_GITHUB_LINK =
  "https://github.com/cstankov/portfolio/tree/master";

// Expense Tracker Constants
export const EX_TRACKER_NAME = "Expense Tracker";
export const EX_TRACKER_DESCRIPTION1 =
  "The expense tracker is an easy-to-use application that records all your monthly expenses which you can add, edit and delete them at anytime. Your expense costs are also displayed by charts using charts.js.";
export const EX_TRACKER_TECH = "ReactJS, HTML, CSS, MongoDB, Node.js";
export const EX_TRACKER_GITHUB_LINK =
  "https://github.com/cstankov/ExpenseTracker";

// COVID Data Mining Constants
export const COVID_MINING_NAME = "Data Mining - COVID-19";
export const COVID_MINING_DESCRIPTION1 =
  "Performed an exploratory data analysis on a COVID-19 dataset with the use of statistics and plots. After we utilized Sklearn’s Linear SVM, random forest and a LGBM classifier to predict the outcome of a patient and to do a comparative analysis on the models.";
export const COVID_MINING_TECH = "Python, Jupyter Notebook, Sklearn";
export const COVID_MINING_GITHUB_LINK =
  "https://github.com/cstankov/COVID19-DataMining";

// Database Management Systems constants
export const DBMS_NAME = "YASE - Database Management System";
export const DBMS_DESCRIPTION1 =
  "YASE is a fully working database management system that utilizes a skiplist for indexing and contains fully functional logging. GoogleTest was used in our test-driven environment.";
export const DBMS_TECH = "C++, Google Test, CMake";
export const DBMS_GITHUB_LINK = "https://github.com/cstankov/CMPT454";

// Note Application constants
export const NOTE_APP_NAME = "NoteApp";
export const NOTE_APP_DESCRIPTION1 =
  "NoteApp is a web-based video note taking application. A user can add personal notes, like comments, as they play the video and later be able to view these notes while the video is being played back with their asssociated timestamp.";
export const NOTE_APP_TECH = "Javascript, HTML, CSS, EJS, Node.js";
export const NOTE_APP_GITHUB_LINK = "https://github.com/cstankov/NoteApp";

// Comment Abuse Tracker constants
export const COMMENT_ABUSE_NAME = "Comment Abuse Tracker";
export const COMMENT_ABUSE_DESCRIPTION1 =
  "A natural language processing comment abuse classifier which uses rectified linear unit deep neural network to effectively classify bad words and hate speech within the input data.";
export const COMMENT_ABUSE_TECH = "Python, Jupyter Notebook, PyTorch";
export const COMMENT_ABUSE_GITHUB_LINK =
  "https://github.com/cstankov/comment_abuse_tracker";

// Bilby constants
export const BILBY_NAME = "Bilby Compiler";
export const BILBY_DESCRIPTION1 =
  "Built from the ground up a compiler for a made up language called Bilby. The compiler successfully Lexically, syntaxially and semantically analyzes the input to generate assembly code.";
export const BILBY_TECH = "Java, Assembly";
export const BILBY_GITHUB_LINK = "https://github.com/cstankov/CMPT379";

// Pristine Blinds constants
export const PRISTINE_NAME = "Blinds Cost Calculator";
export const PRISTINE_DESCRIPTION1 =
  "Worked directly with Pristine Blinds owner to conceptualize and develop a house blind cost calculator for the use of their sales team. ";
export const PRISTINE_TECH = "Android Studio, Java";
export const PRISTINE_GITHUB_LINK =
  "https://github.com/cstankov/PrestineBlindsApplication";

// Crypto Analysis constants
export const CRYPTO_ANAL_NAME = "Crypto-Twitter Analysis";
export const CRYPTO_ANAL_DESCRIPTION1 =
  "An exploratory data analysis on the effects that influential people on twitter have on the cryptocurrency market. With Sklearn neural network, random forest and naïve bayes classifiers we hope to see if we are able to predict the price change based on the sentiment analysis of the tweets.";
export const CRYPTO_ANAL_TECH = "Python, Sklearn";
export const CRYPTO_ANAL_GITHUB_LINK =
  "https://github.com/cstankov/CMPT353-CryptoAnaylsisProject";

// Geometry Brawl constants
export const GEOBRAWL_NAME = "Geometry Brawl";
export const GEOBRAWL_DESCRIPTION1 =
  "Geometry brawl is a online multiplayer shooter which allows the player to join the server and start playing at anytime. Each players bullet trajectory is affected by the current weather and wind conditions in Burnaby B.C. by the use of Darksky's weather api.";
export const GEOBRAWL_TECH = "Javascript, HTML, CSS, Node.js";
export const GEOBRAWL_GITHUB_LINK = "https://github.com/cstankov/GeometryBrawl";


